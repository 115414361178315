<template>
  <div>
    <!-- Render title as a hyperlink if block.url exists -->
    <h1 v-if="block.style === 'h1'" class="preserve-whitespace" style="font-size: 36px; font-weight: bold;">
      <a v-if="block.url" :href="fullUrl" class="curve-link" v-html="block.title"></a>
      <span v-else v-html="block.title"></span>
    </h1>
    
    <h2 v-else-if="block.style === 'h2'" class="preserve-whitespace" style="font-size: 20px; font-weight: bold;">
      <a v-if="block.url" :href="fullUrl" class="curve-link" v-html="block.title"></a>
      <span v-else v-html="block.title"></span>
    </h2>
    
    <h3 v-else-if="block.style === 'h3'" class="preserve-whitespace" style="font-size: 16px;">
      <a v-if="block.url" :href="fullUrl" class="curve-link" v-html="block.title"></a>
      <span v-else v-html="block.title"></span>
    </h3>
    
    <div v-else class="preserve-whitespace" style="font-size: 12px;">
      <a v-if="block.url" :href="fullUrl" class="curve-link" v-html="block.title"></a>
      <span v-else v-html="block.title"></span>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  computed: {
    fullUrl() {
        // Check if the block URL starts with 'https://'
        if (this.block.url.startsWith('https://')) {
            return `${this.block.url}`;
        }
        // prepend the base URL to the block URL
        return `${process.env.VUE_APP_FRONTEND_URL}/${this.block.url}`;
    }
  }
};
</script>

<style scoped>
.preserve-whitespace {
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  max-width: 800px;      /* Limit the maximum width to 800px */
  word-wrap: break-word; /* Wrap text when it exceeds the max-width */
  color: #2E2E33;
}
</style>
