import PreAmpHome from '../views/Wetlab/PreAmp/PreAmpHome.vue'
import PreAmpBatch from '../views/Wetlab/PreAmp/PreAmpBatch.vue'
import PreAmpKickOff from '../views/Wetlab/PreAmp/PreAmpKickoff.vue'


export default [
  {
    path: `preamp`,
    name: 'preamp',
    component: PreAmpHome
  },
  {
    path: `preamp/kickoff`,
    name: 'preampkickoff',
    component: PreAmpKickOff
  },
  {
    path: `preamp/preampbatch:pk`,
    name: 'preampbatch',
    component: PreAmpBatch
  },
]