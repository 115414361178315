<template>
    <div v-if="hasContent" class="response-section">
        <div v-if="responseMessage" class="response-message" v-html="responseMessage"></div>
        <div v-if="responseError" class="error-message" v-html="responseError"></div>
        <Table v-if="tableComponentExists" :block="tableData" class="gray-table"/>
        <div v-if="plotImage" class="plot-container">
            <img :src="plotImage" alt="Generated Plot" class="plot-image" />
        </div>
        <div v-if="responseMessage" class="response-message">
            <!-- Render Refresh button when there is a responseMessage -->
            <button v-if="responseMessage" @click="refreshPage" class="refresh-button">Refresh</button>
        </div>
    </div>
</template>

<script>
import Table from './Table.vue';

export default {
    name: 'ResponseComponent',
    props: {
        responseMessage: {
            type: String,
            default: ''
        },
        responseError: {
            type: String,
            default: ''
        },
        tableComponentExists: {
            type: Boolean,
            default: false
        },
        tableData: {
            type: Object,
            default: () => ({})
        },
        plotImage: {
            type: String,
            default: ''
        }
    },
    components: {
        Table
    },
    computed: {
        hasContent() {
            return (
                this.responseMessage ||
                this.responseError ||
                this.tableComponentExists ||
                this.plotImage
            );
        }
    },
    methods: {
        refreshPage() {
            window.location.reload(); // Reloads the current page
        }
    }
};
</script>

<style scoped>
.response-section {
    border: 1px solid #ddd;
}
.response-message {
    font-size: 20px;
    color: green;
    background-color: rgb(249, 250, 251);
    padding: 20px;
}
.error-message {
    font-size: 20px;
    color: red;
    background-color: rgb(249, 250, 251);
    padding: 20px;
}
.gray-table {
    background-color: rgb(249, 250, 251); /* Light gray background */
    padding: 20px; /* Optional padding */
}
.plot-container {
    text-align: center;
    margin-top: 20px;
}
.plot-image {
    max-width: 100%;
    height: auto;
}
.refresh-button {
    border-radius: 5px;
    font-size: 12px;
    padding: 10px 20px;
    background-color: #18B6E9;;
    color: black;
    border: 5px;
    cursor: pointer;
    font-weight: normal;
}

.refresh-button:hover {
    background-color: lightgreen; /* Optional: change background color on hover */
}
</style>
