import ExternalAuth from '@/views/External/CollectionSites/ExternalAuth.vue'
import Denied from '@/views/External/CollectionSites/Denied.vue'
import CollectionSitesHome from '@/views/External/CollectionSites/CollectionSitesHome.vue'
import KitStatus from '@/views/External/CollectionSites/KitStatus.vue'
import ReceiveKits from '@/views/External/CollectionSites/ReceiveKits.vue'
import Manifest from '@/views/External/CollectionSites/Manifest.vue'



export default [
  {
    path: `auth`,
    component: ExternalAuth,
    name: 'externalauth'
  },
  {
    path: `denied`,
    component: Denied,
    name: 'denied'
  },
  {
    path: `collectionsites/home`,
    component: CollectionSitesHome,
    name: 'collectionsiteshome'
  },
  {
    path: `collectionsites/kit_status`,
    component: KitStatus
  },
  {
    path: `collectionsites/markkitsreceived`,
    component: ReceiveKits
  },
  {
    path: `collectionsites/manifest`,
    component: Manifest
  },
]

