<template>
  <div style="overflow-x: auto;">
    <div :style="gridStyles" class="grid">
      <template v-for="row in block.rows" :key="row">
        <template v-for="col in block.columns" :key="col">
          <div
            class="cell"
            :class="{ occupied: isOccupied(row, col) }"
            style="font-size: 12px;"
          >
            {{ row }}{{ col }}
            <div v-if="isOccupied(row, col)">
              <br />
              <a
                :href="`${frontendUrl}/${getTube(row, col).url}`"
                style="font-size: 12px; color: #18B6E9; font-weight: bold;"
              >
                {{ getTube(row, col).name }}
              </a>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  computed: {
    // Access the environment variable in the computed property
    frontendUrl() {
      return process.env.VUE_APP_FRONTEND_URL;  // Access environment variable here
    },
    gridStyles() {
      return {
        display: 'grid',
        'grid-template-rows': `repeat(${this.block.row_number}, 1fr)`,
        'grid-template-columns': `repeat(${this.block.column_number}, 100px)`,
        'grid-column-gap': '0',
        'grid-row-gap': '0',
      };
    }
  },
  methods: {
    isOccupied(row, col) {
      return this.block.tubes.some(tube => tube.row === row && tube.column === col);
    },
    getTube(row, col) {
      return this.block.tubes.find(tube => tube.row === row && tube.column === col);
    },
  }
};
</script>

<style scoped>
.row {
}

.cell {
  border: 1px solid #ccc;
  display: flex;
  align-items: left;
  justify-content: left;
  height: 60px;
  width: 100px;
}
.cell.occupied {
  background-color: #aaffaa;
}
</style>
