<template>
    <div>

        <div v-if="!isExternalLoggedIn">
            <ResponseComponent
            :responseMessage="responseMessage"
            :responseError="responseError"
            :tableComponentExists="tableComponentExists"
            :tableData="tableData"
            />

            <component 
                v-for="block in pageElements" 
                :key="block.index" 
                :is="block.component" 
                :block="block" 
                @formSubmitted="submitAuthForm"
            ></component>
        </div>

        <div v-else>
            <div @click="onLogout" class="logout-button">
                Logout
            </div>
        </div>
    </div>

</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js';
import axios from 'axios';

export default {
    mixins: [ActionMixin],
    data() {
    return {
        authorizationUrl: '',
        user: '',
        isExternalLoggedIn: localStorage.getItem('externalAuthToken') ? true : false,  
    };
  },
    mounted() {
        document.title = `Collection Sites Authentication`;
    },
    created() {
        this.externalFetchPageStructure('/external/auth/');
    },
    methods: {
        async submitAuthForm(formData) {
            // Clear previous responses
            this.responseError = '';
            this.responseMessage = '';
            this.isLoading = true;    // Start loading

            const formPayload = new FormData();
            for (const [key, value] of Object.entries(formData)) {
                formPayload.append(key, value);
            }

            try {
                const response = await axios.post(`${process.env.VUE_APP_EXTERNAL_URL}/auth/`, formPayload);
                if (response.data.success) {
                    localStorage.setItem('externalAuthToken', response.data.token);
                    this.$router.push({ name: 'collectionsiteshome' })
                        .then(() => {
                            window.location.reload();
                        });
                }
                if (response.data.error) {
                        this.responseError = response.data.error;
                }
            } catch (error) {
                console.error('Error occured on response:', error);
                this.responseError = error;
            } finally {
                this.isLoading = false;    // End loading
            }
        },
        onLogout(){
            console.log('hi');
            localStorage.removeItem('externalAuthToken');
            this.isExternalLoggedIn = false;
            window.location.reload(); // Reload the page or handle post-logout behavior
        },
    }
};
</script>

<style>
.logout-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #18B6E9;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.logout-button:hover {
  background-color: lightgreen; /* Optional: Change on hover */
}
</style>