<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />
    
    <component 
      v-for="block in pageElements" 
      :key="block.index" 
      :is="block.component" 
      :block="block" 
      @formSubmitted="submitForm"
    ></component>
    
  </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js'

export default {
  mixins: [ActionMixin],
  mounted() {
    document.title = `PreAmpBatch Kickoff`
  },
  created() {
    this.fetchPageStructure(`${process.env.VUE_APP_WETLAB_URL}/preamp/kickoff/`);  // Fetch data when component is created
  },
  methods: {
    submitForm(formData) {
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/preamp/kickoff/`, formData);
    },
  },
  computed: {
    // Additional logic to determine whether to render pageElements or not
    showPageElements() {
      return !this.responseMessage; // Page elements are hidden when a responseMessage exists
    }
  },
};
</script>

