import AccessionHome from '@/views/Wetlab/Accession/AccessionHome.vue'
import CrcPage from '@/views/Wetlab/Accession/CrcPage.vue'
import AccessionRack from '@/views/Wetlab/Accession/AccessionRack.vue'


export default [
  {
    path: `accession`,
    name: 'accession',
    component: AccessionHome
  },
  {
    path: `accession/crc`,
    name: 'crc',
    component: CrcPage
  },
  {
    path: `accession/accessionrack`,
    name: 'accessionrack',
    component: AccessionRack
  },
]
