<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <h1 style="font-size: 24px; font-weight: bold;"> You have been locked out due to too many failed attempts.</h1>
    <h1 style="font-size: 24px; font-weight: bold;"> Email becca@curvebio.com if you need assistance. </h1>

  </div>
</template>

