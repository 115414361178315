<template>
  <div class="home-container">
    <!-- Header Logo Section -->
    <div class="header">
        <div class="header-logo">
        <img src="@/assets/curvebio-full-logo.png" alt="Curve Biosciences Logo"/>
        </div>

        <!-- Navigation Links (Aligned to the right) -->
        <div class="header-links">
            <a href="https://www.curvebio.com/external">Collection Sites</a>
            <a href="https://www.curvebio.com/wetlab">Wetlab</a>
        </div>
    </div>


    <!-- Main Content Section -->
    <div class="content">
      <div class="image-and-text">
        <!-- Image of liver and hand -->
        <img src="@/assets/liver-img.png" alt="Liver" class="liver-image" />

        <!-- Text content -->
        <div class="text-overlay">
          <h1>The Right Data for the Right Answers to the Right Patients</h1>
        </div>
      </div>
    </div>

    <!-- Footer Section -->
    <div class="footer">
      <div class="footer-content">
        <p>
          901 Mariners Island Blvd,<br />
          San Mateo, CA<br />94404
        </p>
        <a href="mailto:contact@curvebio.com">contact@curvebio.com</a>

        <!-- Social Media Links -->
        <div class="social-links">
          <a href="https://www.linkedin.com/company/curvebio/" target="_blank">
            <img src="@/assets/linkedin-img.png" alt="LinkedIn" class="social-icon" />
          </a>
        </div>

        <p>© 2023 by Curve Bio</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    document.title = 'Curve Biosciences'
  }
};
</script>

<style scoped>

/* Universal Light Mode Styles */
body {
    background-color: white;
    color: #2E2E33; /* Dark text for contrast on a white background */
}

.home-container {
  font-family: 'Avenir light', sans-serif;
  background-color: white; 
  color: #e0e0e0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.header-logo img {
  height: 100px; /* Adjust as needed */
}

.header-links {
  display: flex;
  gap: 50px; /* Space between the links */
  margin-right: 50px;
}

.header-links a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s;
}

.header-links a:hover {
  color: #0073e6; /* Change to your desired hover color */
}

.image-and-text {
  position: relative;
}

.liver-image {
  max-width: 1500px;
  height: auto;
}

.text-overlay {
  text-align: center;
  transform: translate(0%, -150%);
  background-color: rgba(255, 255, 255, 0.4);
  padding: 50px;
}

.text-overlay h1 {
  font-size: 36px;
  color: black;
}

.footer {
  transform: translate(0%, -125%);
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 0 auto;
  color: black;
}

.footer-content p {
  font-size: 14px;
}

.footer-content a {
  font-size: 14px;
  color: #0073b1;
  text-decoration: none;
}

.footer-content .social-links {
  display: flex;
  justify-content: center;
}

.footer-content .social-icon {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
</style>
